export var SOPStackFrecuency;
(function (SOPStackFrecuency) {
    SOPStackFrecuency["Weekly"] = "Weekly";
    SOPStackFrecuency["Monthly"] = "Monthly";
    SOPStackFrecuency["Every_Three_Months"] = "Every_Three_Months";
    SOPStackFrecuency["Every_Six_Months"] = "Every_Six_Months";
    SOPStackFrecuency["Annually"] = "Annually";
})(SOPStackFrecuency || (SOPStackFrecuency = {}));
export var SOPStatus;
(function (SOPStatus) {
    SOPStatus["Pending"] = "Pending";
    SOPStatus["Complete"] = "Complete";
})(SOPStatus || (SOPStatus = {}));
export var SOPAssignedType;
(function (SOPAssignedType) {
    SOPAssignedType["Customer"] = "Customer";
    SOPAssignedType["Manager"] = "Manager";
    SOPAssignedType["Company"] = "Company";
})(SOPAssignedType || (SOPAssignedType = {}));
