import { useInstallZapierIntegration } from '@/services/api/zapier';
import AppState from '@/services/state/AppState';
import { Container, LoadingOverlay } from '@mantine/core';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const InstallZapierIntegration = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('client_id');
  const state = searchParams.get('state');
  const redirectUri = searchParams.get('redirect_uri');

  const installZapierIntegration = useInstallZapierIntegration();

  useEffect(() => {
    installZapierIntegration.mutateAsync({ clientId, state, redirectUri })
      .then(r => window.open(r.data, '_self'))
      .catch(() => navigate('/'));
  }, []);

  return (
    <>
      <Container fluid style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column', flexWrap: 'wrap' }}>
        <LoadingOverlay visible />
      </Container>
    </>
  );
};

export default InstallZapierIntegration;
