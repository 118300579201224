import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import CheckVersionPage from '@components/screens/Auth/CheckVersion';
import { getVersion } from '@services/api/auth';
import { useAuth } from '@services/hooks/useAuth';
import { initNotifications } from '@services/pushNotifications';
import { initDemoNotifications } from '@services/pushNotifications/demo';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import semver from 'semver';

// right way of logging things on Mobile device iOS Android
let log = console.log.bind(console);

const CapacitorWrapper = () => {
  const auth = useAuth();
  // const appState = AppState.useContainer();

  const [isCurrVersion, setIsCurrVersion] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split('.ai').pop();
      log('SLUG', event.url);
      if (slug) {
        log('NAVIGATE SLUG', slug);
        navigate(slug);
      }
    });
  }, []);

  const checkVersionData = useQuery(
    ['version'],
    () => getVersion(),
    {
      refetchInterval: 10000,
    },
  );

  const checkVersion = async () => {
    const currVersion = checkVersionData?.data?.data?.data?.version;
    if (Capacitor.isNativePlatform()) {
      const app = await App.getInfo();
      log('APP-INSTALLED-VERSION', app.version, 'APP-CURRENT-VERSION', currVersion, semver.gte(app.version, currVersion));
      if (currVersion !== undefined && semver.gte(app.version, currVersion)) {
        setIsCurrVersion(true);
      } else {
        setIsCurrVersion(false);
      }
    }
  };

  useEffect(() => {
    checkVersion();
  }, [checkVersionData?.data?.data?.data?.version]);

  useEffect(() => {
    (async () => {
      const { platform } = await Device.getInfo();
      // appState.installed.setPlatform(platform);
    })();
  }, []);

  useEffect(() => {
    if (auth.accessToken && Capacitor.isNativePlatform()) {
      if (!auth.isDemo) {
        initNotifications(
          auth.accessToken,
          ({ data }) => {
            if (data?.type === 'message') {
              navigate('/chat', { state: { newMessages: true } });
            } else if (data?.type === 'review') {
              navigate(`/review/${data.id}`);
            } else if (data?.type === 'report') {
              navigate(`/reports/${data.id}`);
            }
          },
        );
      } else {
        initDemoNotifications(
          auth.accessToken,
          ({ data }) => {
            if (data?.type === 'message') {
              navigate('/demo/chat?notification=true', { state: { newMessages: true } });
            } else if (data?.type === 'review') {
              navigate(`/demo/review/${data.id}`);
            } else if (data?.type === 'report') {
              navigate(`/demo/reports/${data.id}`);
            }
          },
        );
      }
    }
  }, [auth.accessToken, auth.isDemo]);

  return isCurrVersion ? <Outlet /> : <CheckVersionPage />;
};

export default CapacitorWrapper;
