export var FONTS;
(function (FONTS) {
    FONTS["Arial"] = "Arial";
    FONTS["Verdana"] = "Verdana";
    FONTS["Tahoma"] = "Tahoma";
    FONTS["TrebuchetMS"] = "TrebuchetMS";
    FONTS["TimesNewRoman"] = "TimesNewRoman";
    FONTS["Georgia"] = "Georgia";
    FONTS["Garamond"] = "Garamond";
    FONTS["CourierNew"] = "CourierNew";
    FONTS["BrushScriptMT"] = "BrushScriptMT";
})(FONTS || (FONTS = {}));
export var SUBSCRIBER_STATUS;
(function (SUBSCRIBER_STATUS) {
    SUBSCRIBER_STATUS["ACTIVE"] = "ACTIVE";
    SUBSCRIBER_STATUS["BOUNCED"] = "BOUNCED";
    SUBSCRIBER_STATUS["UNSUBSCRIBED"] = "UNSUBSCRIBED";
    SUBSCRIBER_STATUS["SPAM"] = "SPAM";
})(SUBSCRIBER_STATUS || (SUBSCRIBER_STATUS = {}));
export var FORMAT;
(function (FORMAT) {
    FORMAT["snippet"] = "snippet";
    FORMAT["snippetV2"] = "snippetV2";
    FORMAT["popup"] = "popup";
    FORMAT["page"] = "page";
})(FORMAT || (FORMAT = {}));
export var FORM_POPUP_OPTIONS;
(function (FORM_POPUP_OPTIONS) {
    FORM_POPUP_OPTIONS["immediately"] = "immediately";
    FORM_POPUP_OPTIONS["after-10-seconds"] = "after-10-seconds";
    FORM_POPUP_OPTIONS["after-30-seconds"] = "after-30-seconds";
    FORM_POPUP_OPTIONS["at-30-percent-of-pageview"] = "at-30-percent-of-pageview";
})(FORM_POPUP_OPTIONS || (FORM_POPUP_OPTIONS = {}));
export var COMPLIANCE_ACTION;
(function (COMPLIANCE_ACTION) {
    COMPLIANCE_ACTION["APPROVAL"] = "APPROVAL";
    COMPLIANCE_ACTION["REJECT"] = "REJECT";
    COMPLIANCE_ACTION["APPROVAL_CUSTOM_CONFIG"] = "APPROVAL_CUSTOM_CONFIG";
})(COMPLIANCE_ACTION || (COMPLIANCE_ACTION = {}));
export var USER_MESSAGES_TYPES;
(function (USER_MESSAGES_TYPES) {
    USER_MESSAGES_TYPES["DELIVERY_SUCCESSFULL"] = "DELIVERY_SUCCESSFULL";
    USER_MESSAGES_TYPES["CSV_PROCESSED_SUCCESSFULL"] = "CSV_PROCESSED_SUCCESSFULL";
    USER_MESSAGES_TYPES["CSV_PROCESSED_ERROR"] = "CSV_PROCESSED_ERROR";
    USER_MESSAGES_TYPES["FORM_PAGE_GENERATED"] = "FORM_PAGE_GENERATED";
    USER_MESSAGES_TYPES["SUBSCRIBERS_NOT_IMPORTED"] = "SUBSCRIBERS_NOT_IMPORTED";
    USER_MESSAGES_TYPES["PAYMENT_SUCCESSFULL"] = "PAYMENT_SUCCESSFULL";
    USER_MESSAGES_TYPES["VERIFIED_EMAIL"] = "VERIFIED_EMAIL";
    USER_MESSAGES_TYPES["COMPLIANCE_APPROVAL"] = "COMPLIANCE_APPROVAL";
    USER_MESSAGES_TYPES["COMPLIANCE_REJECT"] = "COMPLIANCE_REJECT";
    USER_MESSAGES_TYPES["SUPPRESSED_LIST_PROCESSED_SUCCESSFULL"] = "SUPPRESSED_LIST_PROCESSED_SUCCESSFULL";
    USER_MESSAGES_TYPES["SUPPRESSED_LIST_PROCESSED_ERROR"] = "SUPPRESSED_LIST_PROCESSED_ERROR";
})(USER_MESSAGES_TYPES || (USER_MESSAGES_TYPES = {}));
export var MBERROR_CODES;
(function (MBERROR_CODES) {
    MBERROR_CODES["DUPLICATED_DRAFT_NAME"] = "DUPLICATED_DRAFT_NAME";
    MBERROR_CODES["NOT_ALLOWED_SEND_EMAIL"] = "NOT_ALLOWED_SEND_EMAIL";
    MBERROR_CODES["DUPLICATED_SEQUENCE_NAME"] = "DUPLICATED_SEQUENCE_NAME";
    MBERROR_CODES["NEED_UPGRADE_TO_PREMIUM"] = "NEED_UPGRADE_TO_PREMIUM";
    MBERROR_CODES["PHONE_NUMBER_ALREADY_IN_USE"] = "PHONE_NUMBER_ALREADY_IN_USE";
})(MBERROR_CODES || (MBERROR_CODES = {}));
export var IMAGE_UPLOADED_FROM;
(function (IMAGE_UPLOADED_FROM) {
    IMAGE_UPLOADED_FROM["DEVICE"] = "DEVICE";
    IMAGE_UPLOADED_FROM["UNSPLASH"] = "UNSPLASH";
    IMAGE_UPLOADED_FROM["YOUTUBE"] = "YOUTUBE";
    IMAGE_UPLOADED_FROM["ASSETS"] = "ASSETS";
})(IMAGE_UPLOADED_FROM || (IMAGE_UPLOADED_FROM = {}));
export var EMAIL_EDITOR_TYPE;
(function (EMAIL_EDITOR_TYPE) {
    EMAIL_EDITOR_TYPE["CODE"] = "CODE";
    EMAIL_EDITOR_TYPE["TIPTAP"] = "TIPTAP";
})(EMAIL_EDITOR_TYPE || (EMAIL_EDITOR_TYPE = {}));
export var SEGMENT_ORIGIN;
(function (SEGMENT_ORIGIN) {
    SEGMENT_ORIGIN["FILE"] = "FILE";
    SEGMENT_ORIGIN["FORM"] = "FORM";
    SEGMENT_ORIGIN["DYNAMIC"] = "DYNAMIC";
    SEGMENT_ORIGIN["MANUALLY"] = "MANUALLY";
})(SEGMENT_ORIGIN || (SEGMENT_ORIGIN = {}));
// DYN SEGMENTS
export var BEHAVIOR_ACTION;
(function (BEHAVIOR_ACTION) {
    BEHAVIOR_ACTION["NOT_OPENED"] = "NOT_OPENED";
    BEHAVIOR_ACTION["NOT_CLICKED"] = "NOT_CLICKED";
    BEHAVIOR_ACTION["NOT_RECEIVED"] = "NOT_RECEIVED";
    BEHAVIOR_ACTION["OPENED"] = "OPENED";
    BEHAVIOR_ACTION["CLICKED"] = "CLICKED";
    BEHAVIOR_ACTION["RECEIVED"] = "RECEIVED";
    BEHAVIOR_ACTION["IN_SEGMENT"] = "IN_SEGMENT";
    BEHAVIOR_ACTION["NOT_IN_SEGMENT"] = "NOT_IN_SEGMENT";
})(BEHAVIOR_ACTION || (BEHAVIOR_ACTION = {}));
export var STRING_OPERATOR;
(function (STRING_OPERATOR) {
    STRING_OPERATOR[STRING_OPERATOR["IS_EQUAL"] = 0] = "IS_EQUAL";
    STRING_OPERATOR[STRING_OPERATOR["IS_NOT_EQUAL"] = 1] = "IS_NOT_EQUAL";
    STRING_OPERATOR[STRING_OPERATOR["CONTAINS"] = 2] = "CONTAINS";
    STRING_OPERATOR[STRING_OPERATOR["NOT_CONTAIN"] = 3] = "NOT_CONTAIN";
    STRING_OPERATOR[STRING_OPERATOR["BEGINS_WITH"] = 4] = "BEGINS_WITH";
    STRING_OPERATOR[STRING_OPERATOR["ENDS_WITH"] = 5] = "ENDS_WITH";
    STRING_OPERATOR[STRING_OPERATOR["IS_EMPTY"] = 6] = "IS_EMPTY";
    STRING_OPERATOR[STRING_OPERATOR["IS_NOT_EMPTY"] = 7] = "IS_NOT_EMPTY";
})(STRING_OPERATOR || (STRING_OPERATOR = {}));
export var NUMBER_OPERATOR;
(function (NUMBER_OPERATOR) {
    NUMBER_OPERATOR[NUMBER_OPERATOR["IS_EQUAL"] = 0] = "IS_EQUAL";
    NUMBER_OPERATOR[NUMBER_OPERATOR["IS_NOT_EQUAL"] = 1] = "IS_NOT_EQUAL";
    NUMBER_OPERATOR[NUMBER_OPERATOR["IS_NOT_EMPTY"] = 2] = "IS_NOT_EMPTY";
    NUMBER_OPERATOR[NUMBER_OPERATOR["BIGGER_THAN"] = 3] = "BIGGER_THAN";
    NUMBER_OPERATOR[NUMBER_OPERATOR["SMALLER_THAN"] = 4] = "SMALLER_THAN";
    NUMBER_OPERATOR[NUMBER_OPERATOR["BIGGER_OR_EQUAL_THAN"] = 5] = "BIGGER_OR_EQUAL_THAN";
    NUMBER_OPERATOR[NUMBER_OPERATOR["SMALLER_OR_EQUAL_THAN"] = 6] = "SMALLER_OR_EQUAL_THAN";
})(NUMBER_OPERATOR || (NUMBER_OPERATOR = {}));
export var DATE_OPERATOR;
(function (DATE_OPERATOR) {
    DATE_OPERATOR[DATE_OPERATOR["IS_EQUAL"] = 0] = "IS_EQUAL";
    DATE_OPERATOR[DATE_OPERATOR["IS_NOT_EQUAL"] = 1] = "IS_NOT_EQUAL";
    DATE_OPERATOR[DATE_OPERATOR["IS_NOT_EMPTY"] = 2] = "IS_NOT_EMPTY";
    DATE_OPERATOR[DATE_OPERATOR["AFTER"] = 3] = "AFTER";
    DATE_OPERATOR[DATE_OPERATOR["BEFORE"] = 4] = "BEFORE";
    DATE_OPERATOR[DATE_OPERATOR["AFTER_OR_IN"] = 5] = "AFTER_OR_IN";
    DATE_OPERATOR[DATE_OPERATOR["BEFORE_OR_IN"] = 6] = "BEFORE_OR_IN";
})(DATE_OPERATOR || (DATE_OPERATOR = {}));
export var BOOLEAN_OPERATOR;
(function (BOOLEAN_OPERATOR) {
    BOOLEAN_OPERATOR[BOOLEAN_OPERATOR["IS_TRUE"] = 0] = "IS_TRUE";
    BOOLEAN_OPERATOR[BOOLEAN_OPERATOR["IS_FALSE"] = 1] = "IS_FALSE";
    BOOLEAN_OPERATOR[BOOLEAN_OPERATOR["IS_NULL"] = 2] = "IS_NULL";
})(BOOLEAN_OPERATOR || (BOOLEAN_OPERATOR = {}));
export var SUBSCRIBER_FIELD_TYPE;
(function (SUBSCRIBER_FIELD_TYPE) {
    SUBSCRIBER_FIELD_TYPE["DATE"] = "date";
    SUBSCRIBER_FIELD_TYPE["FLOAT"] = "float";
    SUBSCRIBER_FIELD_TYPE["STRING"] = "string";
    SUBSCRIBER_FIELD_TYPE["BOOLEAN"] = "boolean";
})(SUBSCRIBER_FIELD_TYPE || (SUBSCRIBER_FIELD_TYPE = {}));
export var MANUALLY_ADDED_SEGMENT_NAME = 'Manually added';
export var ADDED_VIA_API_SEGMENT_NAME = 'Added via API';
export var ASSET_TYPE;
(function (ASSET_TYPE) {
    ASSET_TYPE["IMAGE"] = "IMAGE";
    ASSET_TYPE["CSV"] = "CSV";
    ASSET_TYPE["VIDEO"] = "VIDEO";
    ASSET_TYPE["DOCUMENT"] = "DOCUMENT";
})(ASSET_TYPE || (ASSET_TYPE = {}));
export var REVIEW_STATUS;
(function (REVIEW_STATUS) {
    REVIEW_STATUS["READY_FOR_REVIEW"] = "READY_FOR_REVIEW";
    REVIEW_STATUS["CHANGES_REQUESTED"] = "CHANGES_REQUESTED";
    REVIEW_STATUS["APPROVED"] = "APPROVED";
})(REVIEW_STATUS || (REVIEW_STATUS = {}));
export var CONVERSION_METRICS;
(function (CONVERSION_METRICS) {
    CONVERSION_METRICS["CHECKOUT_COMPLETED"] = "CHECKOUT_COMPLETED";
    CONVERSION_METRICS["CHECKOUT_STARTED"] = "CHECKOUT_STARTED";
})(CONVERSION_METRICS || (CONVERSION_METRICS = {}));
export var SEQUENCE_TRIGGER;
(function (SEQUENCE_TRIGGER) {
    SEQUENCE_TRIGGER["SEGMENT"] = "SEGMENT";
    SEQUENCE_TRIGGER["INTERVAL_LATER"] = "INTERVAL_LATER";
})(SEQUENCE_TRIGGER || (SEQUENCE_TRIGGER = {}));
export var SEQUENCE_TYPE;
(function (SEQUENCE_TYPE) {
    SEQUENCE_TYPE["SEGMENT"] = "segment";
    SEQUENCE_TYPE["SHOPIFY"] = "shopify";
})(SEQUENCE_TYPE || (SEQUENCE_TYPE = {}));
export var SEQUENCE_METRIC_TRIGGER;
(function (SEQUENCE_METRIC_TRIGGER) {
    SEQUENCE_METRIC_TRIGGER["ADD_PRODUCT_TO_CART"] = "ADD_PRODUCT_TO_CART";
    SEQUENCE_METRIC_TRIGGER["CHECKOUT_COMPLETED"] = "CHECKOUT_COMPLETED";
})(SEQUENCE_METRIC_TRIGGER || (SEQUENCE_METRIC_TRIGGER = {}));
export var MESSAGE_TYPE;
(function (MESSAGE_TYPE) {
    MESSAGE_TYPE["TEXT"] = "TEXT";
    MESSAGE_TYPE["REVIEW"] = "REVIEW";
    MESSAGE_TYPE["STATS"] = "STATS";
    MESSAGE_TYPE["PILL"] = "PILL";
})(MESSAGE_TYPE || (MESSAGE_TYPE = {}));
export var PILL_MESSAGE_CATEGORY;
(function (PILL_MESSAGE_CATEGORY) {
    PILL_MESSAGE_CATEGORY["DRAFT_CREATED"] = "DRAFT_CREATED";
    PILL_MESSAGE_CATEGORY["DRAFT_UPDATED"] = "DRAFT_UPDATED";
    PILL_MESSAGE_CATEGORY["DELIVERY_SCHEDULED"] = "DELIVERY_SCHEDULED";
    PILL_MESSAGE_CATEGORY["DELIVERY_SENT"] = "DELIVERY_SENT";
    PILL_MESSAGE_CATEGORY["SEQUENCE_CREATED"] = "SEQUENCE_CREATED";
    PILL_MESSAGE_CATEGORY["SEQUENCE_EMAIL_CREATED"] = "SEQUENCE_EMAIL_CREATED";
    PILL_MESSAGE_CATEGORY["SEQUENCE_EMAIL_UPDATED"] = "SEQUENCE_UPDATED";
    PILL_MESSAGE_CATEGORY["SEQUENCE_TURNED_ON"] = "SEQUENCE_TURNED_ON";
    PILL_MESSAGE_CATEGORY["SEQUENCE_TURNED_OFF"] = "SEQUENCE_TURNED_OFF";
})(PILL_MESSAGE_CATEGORY || (PILL_MESSAGE_CATEGORY = {}));
