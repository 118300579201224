import { CONVERSION_METRICS, Web } from '@/sharedTypes';
import ReloadTokens, { ReloadTokenType } from '../state/ReloadTokensState';
import { genericAuthRequest, RuntimeBackend, useMailberryMutate, useMailberryQuery } from './base';

export function useInstallZapierIntegration() {
  return useMailberryMutate<{ data: string | null; }, { clientId: string; state: string; redirectUri: string; }>({
    f: (accessToken, userData) => {
      return genericAuthRequest(accessToken, 'post', `/zapier/auth`, userData, RuntimeBackend);
    },

    reloadTokenType: ReloadTokenType.UI,
  });
}
